import './components/hello';
// import  './components/scrollspy';
// import './components/formComponent';
// import textFit from 'textfit';
import 'waypoints/lib/noframework.waypoints';


// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';

const mainWrap = document.querySelector('main');



function toggle(selector,cls){
    let target = document.querySelectorAll(selector);
    if(target){
        target.forEach(text => {
            text.addEventListener('click', function(){
                this.classList.toggle(cls);
            })
        })
    }
}
//
// toggle('.header__trigger','is-open');


//example footer height detect
// function _getAbsoluteHeight(el) {
//     el = (typeof el === 'string') ? document.querySelector(el) : el;
//     var styles = window.getComputedStyle(el);
//     var margin = parseFloat(styles['marginTop']) +
//                  parseFloat(styles['marginBottom']);
//     return Math.ceil(el.offsetHeight + margin);
// }

// //footer height variable
// function setVariableFooterHeight(){
//     setTimeout(() => {
//         const footerH = _getAbsoluteHeight('.footer');

//         if(footerH !== 0){
//             document.documentElement.style.setProperty('--footerHeight', footerH + 'px');
//         }

//     }, 200);
// }

// setVariableFooterHeight();

// window.addEventListener("orientationchange", function() {
//     setVariableFooterHeight();
// });

(function() {
    var throttle = function(type, name, obj) {
        obj = obj || window;
        var running = false;
        var func = function() {
            if (running) { return; }
            running = true;
             requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };

    throttle("resize", "optimizedResize");
    throttle("scroll", "optimizedScroll");
})();

window.addEventListener("optimizedResize", function() {
    vhCorrector();
})


let menuTrigger = document.getElementById('mob-menu-trigger');
if(!!menuTrigger){
    menuTrigger.addEventListener('click', function(){
        document.body.classList.toggle('menu-open');
        this.setAttribute('aria-expanded', document.body.classList.contains('menu-open'));
    })
}


// scroll to
const links = document.querySelectorAll('a[href*="#"]:not([href="#"])');

for (const link of links) {
  link.addEventListener("click", clickHandler);
}

function clickHandler(e) {
    e.preventDefault();
    const href = this.getAttribute("href");
    const offsetTop = document.querySelector(href).offsetTop;
    document.body.classList.remove('menu-open');
    scroll({
        top: offsetTop,
        behavior: "smooth"
    });
}

var waypoints = document.querySelectorAll('.scroll-detector');
for (var i = waypoints.length - 1; i >= 0; i--) {
    var waypoint = new Waypoint({
        element: waypoints[i],
        handler: function(direction) {
            this.element.classList.add('in-view');
        },
        offset: '80%',
    });
}